<template>
  <div class="contentMain">
    <div class="searchDiv">
      <div class="searchLable">项目名称</div>
      <Select v-model="projectId" style="width:200px" placeholder="请选择项目名称" class="selStyle" @on-change="changeSel()" clearable>
        <Option v-for="(item,index) in projectNameList" :value="item.projectId" :key="index">{{ item.projectName }}
        </Option>
      </Select>
      <div class="searchLable searchStatus">项目状态</div>
      <Select v-model="projectStatus" style="width:200px" placeholder="请选择项目状态" class="selStyle" @on-change="changeSel()" clearable>
        <Option value="1">进行中</Option>
        <Option value="2">已结束</Option>
      </Select>
    </div>
    <div class="main">
      <div ref="scrollContainer" @scroll="handleScroll" class="divStyle" v-if="projectList.length!=0">
        <div class="cardDiv" v-for="(item,index) in projectList" :key="index">
          <div class="cardLeft">
            <div class="cardTitle">
              <Input :ref="item.projectId+index" v-model="item.projectName" placeholder="请输入名称" v-if="item.isChangeName" @on-blur="okChangeName(item)" style="width: 100%;" />
              <div class="projectName" v-else>{{ item.projectName }}</div>
              <img src="@/assets/editicon.png" alt="" class="editicon" @click="changeName(item,index)">
            </div>
            <div class="cardContent" @click="goOut(item)">
              <div v-for="(itemJd,indexJd) in item.jdArr" :key="indexJd">{{ itemJd.deliverType }}&nbsp;&nbsp;&nbsp;{{ itemJd.completeNum?itemJd.completeNum:0 }}/{{ itemJd.totalNum?itemJd.totalNum:0 }}
              </div>
              <!-- <div v-if="item.projectProgress != null">项目进度 {{ item.projectProgress }}%</div> -->
            </div>
            <div class="cardStatus" @click="goOut(item)">
              <span v-if="item.projectStatus==1">进行中...</span>
              <span v-else style="color: #BEBEBE;">已结束</span>
            </div>
          </div>
          <div class="cardRight" @click="goOut(item)">
            <img src="@/assets/projectTypeIcon/project_yue.png" alt="" class="projectImg">
          </div>
          <div class="subscribeTo" v-if="item.subscriptionStatus != null">
            <div class="subscribeToMain subscribeToMainOver" v-if="item.subscriptionStatus == 1" @click="unSubscribeToData(item)">
              <span class="circle"></span>
              已订阅
            </div>
            <div class="subscribeToMain" @click="subscribeTo(item)" v-else>
              <span class="circle"></span>
              订阅
            </div>
          </div>
        </div>
        <div class="getOver" v-if="getOver && projectList.length!=0">已加载全部</div>
      </div>
      <div class="noData" v-else>暂无数据</div>
    </div>
    <!-- 订阅协议 -->
    <Modal v-model="subscribeToModal" width="1100" :footer-hide="true">
      <div class="subscribeToModal">
        <div class="subscribeToContent">
          <div class="subscribeToHeader">
            用户授权协议
          </div>
          <div class="subscribeToMain">
            <div class="txtDiv">
              本用户协议（以下简称“协议”）是您（以下简称“用户”）与北京星图数网科技有限公司（以下简称“本公司”）之间就使用本公司提供的PC端产品及相关服务所订立的协议。请您在注册、使用本公司提供的产品或服务前，仔细阅读并充分理解本协议各条款，特别是免除或者限制本公司责任的条款、对用户权利的限制条款、争议解决和法律适用条款等。这些条款可能以加粗字体或其他明显方式提示您注意。您应当审慎阅读并选择接受或不接受本协议。除非您接受本协议所有条款，否则您无权使用当前订阅服务。您的同意行为将视为对本协议的接受，并同意接受本协议各项条款的约束。
            </div>
            <div class="ptitle">一、授订阅功能</div>
            <div class="txtDiv">
              1.用户在使用本公司提供的PC端产品时，需要接收到对应的交付物以及相关邮件推送。<br>
              2.用户在使用该功能之前，需要点击同意授权此订阅功能，以便本公司能够向您发送交付物及邮件推送。<br>
              3.用户同意本公司有权根据业务需要，不时调整、变更或终止该订阅功能，并通过邮件或其他合适的方式通知用户。<br>
              4.用户可以随时通过取消订阅功能来停止接收交付物及邮件推送。取消订阅的具体操作方式将在相关页面进行详细说明。
            </div>
            <div class="ptitle">二、用户权益保护</div>
            <div class="txtDiv">
              1.用户同意本公司将按照本协议的规定及其在相关产品或服务中公布的规则为您提供服务，包括但不限于交付物及邮件推送等。<br>
              2.本公司将采取合理的技术和管理措施来保护用户的个人信息，防止用户信息遭到未经授权的访问、使用或泄露。<br>
              3.在用户使用本公司提供的产品或服务过程中，如发现任何可能损害用户权益的行为，用户可以立即联系我们的客服团队进行投诉。
            </div>
            <div class="ptitle">三、免责声明</div>
            <div class="txtDiv">
              1.用户理解并同意，由于互联网设备故障、网络中断、电力问题等原因，可能导致产品或服务无法正常运行，本公司不承担由此产生的损失或责任。<br>
              2.用户在使用本公司提供的产品或服务过程中，应自行承担风险，本公司不对由于用户自身原因导致的损失或责任承担责任。
            </div>
            <div class="ptitle">四、其他条款</div>
            <div class="txtDiv">
              1.本协议自用户点击同意授权订阅功能之日起生效，有效期为一年。有效期届满后，如果用户继续使用本公司提供的产品或服务，则视为自动续订，除非用户提前通知本公司不再续订。<br>
              2.本公司保留对本协议的最终解释权和随时修改的权利，修改后的协议将在相关页面进行公示。<br>
              3.本协议中的标题仅为方便阅读而设，不影响本协议的解释。
            </div>
          </div>
        </div>
        <div class="downFoot">
          <div class="btn" @click="subscribeToData()">同意并订阅</div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import {
  getProjectList,
  getAllProjectList,
  editProjectName,
  setSubscribeTo,
  unSubscribeTo,
  getInfo,
} from '@/apis/project'
export default {
  data() {
    return {
      projectId: '',
      projectStatus: '',
      projectNameList: [],
      projectList: [],
      pages: 1,
      total: 0,
      getOver: false,
      subscribeToModal: false,
    }
  },
  mounted() {
    this.$track('项目中心', '查看项目中心', `登录进入交付中心页。`)
    //获取项目列表
    this.getData(true)
    //获取所有项目列表
    this.getAllProjectData()
  },
  methods: {
    //获取项目列表
    async getData(init, clear) {
      const { body } = await getProjectList({
        pages: this.pages,
        rows: 10,
        projectStatus: this.projectStatus,
        projectId: this.projectId,
      })
      if (body && body.data && body.data.length != 0) {
        if (clear) {
          this.projectList = []
        }
        body.data.forEach((item) => {
          item.isChangeName = false
          let jdArr = []
          item.deliverTypes.forEach((itemJd, index) => {
            if (index == 0 || index == 1) {
              jdArr.push(itemJd)
            }
          })
          item.jdArr = jdArr
          this.projectList.push(item)
        })
        this.total = body.total
        if (this.total <= this.projectList.length) {
          this.getOver = true
        } else {
          this.getOver = false
        }
        if (body.total > 10 && init) {
          this.pages = 2
          this.getData()
        }
      } else {
        this.total = 0
        this.projectList = []
      }
    },
    //获取所有项目列表
    async getAllProjectData() {
      const { body } = await getAllProjectList()
      if (body && body.length != 0) {
        this.projectNameList = body
      } else {
        this.projectNameList = []
      }
    },
    // 修改赛选内容
    changeSel() {
      this.pages = 1
      this.getData(true, true)
    },
    //修改名称
    changeName(item, index) {
      item.isChangeName = true
      //让input获取焦点
      this.$nextTick(() => {
        this.$refs[item.projectId + index][0].focus()
      })
    },
    //失去焦点/保存修改的名称
    async okChangeName(item) {
      // const { code, message } = await editProjectName({ projectId: "35b8f2f6e7da406388ab3446ca8ca243", projectName: "测试改名字" });
      const { code, message } = await editProjectName({
        projectId: item.projectId,
        projectName: item.projectName,
      })
      if (code == 200) {
        item.isChangeName = false
        this.pages = 1
        // this.getData(true,true);
        this.$Message.success(message)
      } else {
        item.isChangeName = false
        this.$Message.error(message)
      }
    },
    //跳转别的页面并且携带参数
    goOut(item) {
      this.$router.push({
        path: '/deliver/deliverList',
        query: { projectId: item.projectId },
      })
    },
    handleScroll() {
      // 判断是否滚动到底部
      const scrollDistance =
        this.$refs.scrollContainer.scrollHeight -
        this.$refs.scrollContainer.scrollTop -
        this.$refs.scrollContainer.clientHeight
      if (scrollDistance <= 0) {
        // 滚动到底部触发的逻辑
        if (this.total <= this.projectList.length) {
          this.getOver = true
          return
        }
        this.pages++
        this.getData()
      }
    },
    //打开订阅弹窗/订阅
    subscribeTo(params) {
      this.selData = params
      this.subscribeToModal = true
    },
    //订阅
    async subscribeToData() {
      let { code, message } = await setSubscribeTo({
        projectId: this.selData.projectId,
      })
      if (code && code == 200) {
        this.$Message.success(message)
        this.selData.subscriptionStatus = 1
        this.subscribeToModal = false
      } else {
        this.$Message.error(message)
      }
    },
    //取消订阅
    async unSubscribeToData(params) {
      let { code, message } = await unSubscribeTo({
        projectId: params.projectId,
      })
      if (code && code == 200) {
        this.$Message.success(message)
        params.subscriptionStatus = 0
      } else {
        this.$Message.error(message)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.contentMain {
  width: 100vw;
  height: calc(100vh - 70px);
  .searchDiv {
    width: 100vw;
    padding: 0 100px;
    display: flex;
    align-items: center;
    height: 150px;

    .searchLable {
      width: 70px;
      font-size: 14px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #333333;
      margin-right: 10px;
    }

    .searchStatus {
      margin-left: 20px;
    }
  }

  .main {
    height: calc(100vh - 220px);

    .cardDiv {
      width: 325px;
      height: 183px;
      background: #ffffff;
      box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.06);
      border-radius: 24px;
      padding: 40px 20px;
      box-sizing: border-box;
      margin: 0 20px 60px 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 10;
      position: relative;
      //阴影
      &:hover {
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
      }
      .cardLeft {
        width: 150px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 10px;
        .cardTitle {
          width: 100%;
          font-size: 16px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #333333;
          display: flex;
          align-items: center;

          .projectName {
            width: max-content;
            color: #333333;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
          }

          .editicon {
            width: 16px;
            height: 16px;
            margin-left: 5px;
            cursor: pointer;
          }
        }

        .cardContent {
          width: 100%;
          height: 60px;
          font-size: 11px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #666666;
        }

        .cardStatus {
          width: 100%;
          font-size: 12px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #328cff;
        }
      }

      .cardRight {
        cursor: pointer;

        .projectImg {
          width: 140px;
          height: 120px;
        }
      }
      .subscribeTo {
        position: absolute;
        top: 15px;
        right: 15px;
        .subscribeToMain {
          width: 50px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          background: #eceffb;
          border-radius: 4px;
          font-size: 11px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #328cff;
          .circle {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            border: 1px solid #328cff;
            margin-right: 3px;
          }
        }
        .subscribeToMainOver {
          background: #328cff;
          color: #fff;
          .circle {
            background: #fff;
            border: none;
          }
        }
      }
    }

    .divStyle {
      max-height: calc(100vh - 220px);
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
      overflow-y: auto;
      padding: 40px 100px 0;
      margin-top: -40px;
      .getOver {
        width: 100%;
        text-align: center;
        padding: 20px 0;
      }
    }

    .noData {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .scrollStyle {
    height: 100%;
  }
}

::v-deep .selStyle .ivu-select-selection {
  border: none;
  color: #333333;
}

::v-deep .selStyle .ivu-select-arrow {
  color: #bfc3d6;
}

::v-deep .selStyle .ivu-select-placeholder {
  font-size: 12px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN !important;
}

::v-deep .selStyle .ivu-select-selected-value {
  font-size: 12px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN !important;
}

::v-deep .selStyle .ivu-select-item {
  font-size: 12px !important;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN !important;
}

::v-deep .cardTitle .ivu-input {
  height: 24px;
  border: none;
  padding: 0;
  font-size: 16px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #333333;
  // background-color: #e8eaec;
}
//弹窗样式
.subscribeToModal {
  .subscribeToContent {
    width: 100%;
    max-height: 600px;
    padding: 0 30px;
    overflow-y: scroll;
    .subscribeToHeader {
      width: 100%;
      text-align: center;
      font-size: 24px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #333333;
      margin-bottom: 22px;
    }
    .subscribeToMain {
      padding-bottom: 30px;
      .txtDiv {
        font-size: 16px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
        line-height: 26px;
      }
      .ptitle {
        color: #333;
        font-weight: bold;
      }
    }
  }
  .downFoot {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    .btn {
      width: 120px;
      height: 40px;
      background: #328cff;
      border-radius: 20px;
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #ffffff;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
    }
  }
}
::v-deep .ivu-modal-content {
  border-radius: 24px;
}
</style>