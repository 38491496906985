import feach from "@/utils/request";

//获取项目管理列表
export const getProjectList = params => {
  return feach.request({
    url: "/project/selectUserProject",
    method: "get",
    params
  });
};
//获取全部项目名称列表
export const getAllProjectList = params => {
  return feach.request({
    url: "/project/getProjectList",
    method: "get",
    params
  });
};
//获取全部项目名称列表
export const editProjectName = params => {
  return feach.request({
    url: `/project/update/${params.projectId}`,
    method: "put",
    params
  });
};
//订阅
export const setSubscribeTo = params => {
  return feach.request({
    url: `/subscribe/saveSubscribe/${params.projectId}`,
    method: "get",
    params
  });
};
//取消订阅
export const unSubscribeTo = params => {
  return feach.request({
    url: `/subscribe/unsubscribe/${params.projectId}`,
    method: "get",
    params
  });
};
// 获取用户信息
export const getInfo = params => {
  return feach.request({
    url: "/account/getInfo",
    method: "get",
  });
};